import React, {useContext, useEffect, useState} from 'react';
import './RegionSelector.css'
import HomeService from "../../../services/HomeService";
import {IRegion} from "../../../models/models";
import Searchbar from "../Searchbar/Searchbar";
import {useCookies} from "react-cookie";
import {Context} from "../../../index";

interface IRegionSelectorProps {
    name:string;
    regions:IRegion[];
}
function RegionSelector(props:IRegionSelectorProps) {
    const [cookies, setCookie, removeCookie] = useCookies(['RegionName','RegionId']);
    const {store} = useContext(Context)

    const [searchword,setSearchword] = useState<string>("")
    const [visible, setVisible] = useState<boolean>(false)
    const selectRegionHandler = (id:string,name:string) => {
        HomeService.SetRegion(id).then(()=>{
        }).finally( ()=> {
            setCookie("RegionName", name);
            setCookie("RegionId", id);
            store.RegionChanged(id)
        })
    }
    const onClickRegion=(regionId:string, name:string)=>{
        selectRegionHandler(regionId,name)
        setVisible(!visible)
        setSearchword('')
    }

    useEffect(() => {
        if(!cookies.RegionName) setCookie("RegionName","Пермский край")
    }, [cookies.RegionName]);

    const RegionItems = props.regions.map((region)=>{
                return(
                    <div style={(region.name.toLowerCase().includes(searchword.toLowerCase()))?
                        {display:"block"}:
                        {display:"none"}}
                         key={region.regionId}
                        className={`${(region.name === cookies.RegionName)&&"RegionSelect_Name"} RegionSelect_Item`}
                        onClick={()=>{
                            onClickRegion(region.regionId, region.name)
                        }}
                    >
                        {region.name}
                    </div>)
            })

    return (<>
        <div onClick={()=>{setVisible(!visible);setSearchword('')}}>
            {cookies.RegionName}
        </div>
        <div style={visible?{display:"block"}:{display:"none"}} className={"RegionSelect_Box"}>
            <div className={"RegionSelect_Search"}>
                <Searchbar clearInputBool={visible} initialText={"Найти регион"} changeHandler={setSearchword}/>
            </div>
            <div className={"RegionSelect_Items"}>
                {RegionItems}
            </div>
        </div>
    </>)
}
export default RegionSelector