import styles from './SearchInFilter.module.scss'
import React, {ChangeEvent, useEffect, useRef, useState} from "react";

interface ISearchInFilterProps {
	initialText: string;
	changeHandler: (str: string) => void;
	value?: string;
	clearInputBool?: boolean
}

const SearchInFilter = ({initialText, changeHandler, value, clearInputBool}: ISearchInFilterProps) => {
	
	const refInput = useRef<HTMLInputElement>(null);
	
	useEffect(() => {
		if (clearInputBool)
			changeHandler('')
	}, [clearInputBool])
	
	// const onChangeHandler = async (str: string) => {
	// 	changeHandler(str)
	// }
	
	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			refInput.current?.blur();
		}
	};
	
	return (
			<div className={styles.container}>
				<img className={styles.searchbarLogo2} alt={""} src={'/Pictures/search.svg'}/>
				<input className={styles.input}
				       enterKeyHint={"search"}
				       value={value}
				       placeholder={initialText}
				       id={"adminSearch"}
				       onChange={(event) => {changeHandler(event.target.value)}}
				       type={"search"}
				       ref={refInput}
				       onKeyDown={(event) => handleKeyDown(event)}
				/>
				{value&&
					<img className={styles.searchbarLogo1} alt={""} src={'/Pictures/Close.svg'} onClick={() => changeHandler("")} />
				}
			</div>
	)
}

export default SearchInFilter;

