import React, {useEffect, useRef, useState} from "react";
import {searchBrandsByName, searchSuppliersByName} from "../../../../functions/searchByName";
import {sortArrayByActive} from "../../../../functions/sortByActive";
import SearchInFilter from "../../../customUI/SearchInFilter/SearchInFilter";
import styles from "./MobileFilterCatalog.module.scss";
import {Checkbox} from "antd";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {
    IBrandsFilterData,
    ICatalogFilterData,
    ICatalogSelectedFilter,
    ISubCategoryFilterData,
    ITopCategoryFilterData
} from "../../../../models/Catalog/Catalog";
import {BeatLoader} from "react-spinners";
import { Pagination } from 'antd';

interface Props {
    selectedView: number;
    onChangeView: (view: number) => void;
    selectedFilters: ICatalogSelectedFilter;
    filtersData: ICatalogFilterData;
    loading: boolean;
    onChangeFilter: (newFilter: ICatalogSelectedFilter) => void;
    applyFilters: (newFilter: ICatalogSelectedFilter) => void;
    clearFilters: () => void;
    onOpen: (value: boolean) => void;
}

const MobileFilterCatalog = ({
                                 selectedView,
                                 onChangeView,
                                 selectedFilters,
                                 filtersData,
                                 loading,
                                 onChangeFilter,
                                 applyFilters,
                                 clearFilters,
                                 onOpen
                             }: Props) => {
    const [newSelectedFilters, setNewSelectedFilters] = useState(selectedFilters)
    const [currentFilter, setCurrentFilter] = useState<string | null>("Категория");
    const [currentFilterAttr, setCurrentFilterAttr] = useState<string>("");
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [numberOfFilters, setNumberOfFilter] = useState(0)
    const [topCatAttrName, setTopCatAttrName] = useState('')
    const [subCatAttrName, setSubCatAttrName] = useState('')

    const [selectedTopCategory, setSelectedTopCategory] = useState<ITopCategoryFilterData>()
    const [selectedSubCategory, setSelectedSubCategory] = useState<ISubCategoryFilterData>()
    
    const [searchSuppliers, setSearchSuppliers] = useState("")
    const [searchBrands, setSearchBrands] = useState("")
    
    const [currentPageBrands, setCurrentPageBrands] = useState(1);
    const [currentPageSuppliers, setCurrentPageSuppliers] = useState(1);

    const divRef = useRef<HTMLDivElement | null>(null);
    const [heightDiv, setHeightDiv] = useState<number>(400);
    
    const getDivSize = () => {
        if (divRef.current) {
            const newHeight = divRef.current.clientHeight;
            setHeightDiv(newHeight);
        }
    };
    
    useEffect(() => {
        getDivSize();
        window.addEventListener("resize", getDivSize);
    }, []);
    
    const ITEMS_PER_PAGE = (heightDiv - 170) / 22;
    const startIndexSuppliers = (currentPageSuppliers - 1) * ITEMS_PER_PAGE;
    const startIndexBrands = (currentPageBrands - 1) * ITEMS_PER_PAGE;
    const endIndexSuppliers = startIndexSuppliers + ITEMS_PER_PAGE;
    const endIndexBrands = startIndexBrands + ITEMS_PER_PAGE;
    

    useEffect(() => {
        setNewSelectedFilters(selectedFilters)
        setCurrentFilter("Категория")
        setCurrentFilterAttr("")

        let numberF = 0

        if(selectedFilters.topCategoryFilter){
            if(selectedFilters.topCategoryFilter.topCategoryId){
                numberF += 1
            }
            if(selectedFilters.topCategoryFilter.filterAttributes){
                numberF += selectedFilters.topCategoryFilter.filterAttributes.length
            }
        }

        if(selectedFilters.subCategoryFilter){
            if(selectedFilters.subCategoryFilter.subCategoryId){
                numberF += 1
            }
            if(selectedFilters.subCategoryFilter.filterAttributes){
                numberF += selectedFilters.subCategoryFilter.filterAttributes.length
            }
        }

        if(selectedFilters.sellersIds){
            numberF += selectedFilters.sellersIds.length
        }

        if(selectedFilters.brands){
            numberF += selectedFilters.brands.length
        }

        setNumberOfFilter(numberF)

    }, [selectedFilters.topCategoryFilter, selectedFilters.subCategoryFilter, selectedFilters.sellersIds, selectedFilters.brands]);

    useEffect(() => {
        if (newSelectedFilters && filtersData) {
            if (
                newSelectedFilters.topCategoryFilter &&
                filtersData.topCategories &&
                filtersData.topCategories.length > 0
            ) {
                setSelectedTopCategory(
                    filtersData.topCategories.find(
                        (tc) =>
                            tc.topCategoryId ===
                            newSelectedFilters.topCategoryFilter?.topCategoryId
                    )
                );
            }
            if (
                newSelectedFilters.subCategoryFilter &&
                filtersData.subCategories &&
                filtersData.subCategories.length > 0
            ) {
                setSelectedSubCategory(
                    filtersData.subCategories.find(
                        (sc) =>
                            sc.categoryId ===
                            newSelectedFilters.subCategoryFilter?.subCategoryId
                    )
                );
            }
        }
    }, [newSelectedFilters, filtersData]);

    useEffect(() => {
        onOpen(isFilterOpen)
    }, [isFilterOpen])

    const onChange = (value: string, type: string, topCatId?: string) => {
        let newFilters = {...newSelectedFilters};

        switch (type) {
            case "topCat":
                newFilters.topCategoryFilter = {
                    ...newFilters.topCategoryFilter,
                    topCategoryId:
                        newFilters.topCategoryFilter?.topCategoryId === value
                            ? null
                            : value,
                    filterAttributes: null
                };
                newFilters.subCategoryFilter = null;
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "subCat":
                newFilters.topCategoryFilter = {
                    ...newFilters.topCategoryFilter,
                    topCategoryId: topCatId,
                    filterAttributes: newFilters.topCategoryFilter?.filterAttributes
                };
                newFilters.subCategoryFilter = {
                    ...newFilters.subCategoryFilter,
                    subCategoryId:
                        newFilters.subCategoryFilter?.subCategoryId === value
                            ? null
                            : value,
                    filterAttributes: null
                };
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "supplier":
                newFilters.sellersIds = newFilters.sellersIds?.includes(value)
                    ? newFilters.sellersIds.filter((id) => id !== value)
                    : [...newFilters.sellersIds ?? [], value];
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "brand":
                newFilters.brands = newFilters.brands?.includes(value)
                    ? newFilters.brands.filter((id) => id !== value)
                    : [...(newFilters.brands || []), value];
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "topCatOpt":
                newFilters.topCategoryFilter = {
                    ...newFilters.topCategoryFilter,
                    filterAttributes: newFilters.topCategoryFilter?.filterAttributes?.includes(value)
                        ? newFilters.topCategoryFilter.filterAttributes.filter((id) => id !== value)
                        : [...(newFilters.topCategoryFilter?.filterAttributes || []), value]
                };

                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters);
                break;

            case "subCatOpt":
                newFilters.subCategoryFilter = {
                    ...newFilters.subCategoryFilter,
                    filterAttributes: newFilters.subCategoryFilter?.filterAttributes?.includes(value)
                        ? newFilters.subCategoryFilter.filterAttributes.filter((id) => id !== value)
                        : [...(newFilters.subCategoryFilter?.filterAttributes || []), value]
                };

                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters);
                break;

            default:
                break;
        }
    }

    const renderFilterItems = () => {
        switch (currentFilter) {
            case "Категория":
                return (
                    <>
                        <div>
                        <div className={styles.filterTitle}>
                            Категории
                        </div>
                        {filtersData.topCategories?.map((tc: any) => (
                            <div key={tc.topCategoryId}
                                 className={tc.isActive ? styles.filterItem : styles.filterItemDisable}
                                 onClick={() => tc.isActive && onChange(tc.topCategoryId || "", "topCat")}>
                                <Checkbox
                                    checked={tc.topCategoryId === newSelectedFilters.topCategoryFilter?.topCategoryId}
                                    disabled={!tc.isActive}
                                />
                                {tc.name}
                            </div>))
                        }
                        </div>
                    </>
                )
            case "Подкатегория":
                return (
                    <>
                        <div>
                        <div className={styles.filterTitle}>
                            Подкатегории
                        </div>
                        {filtersData.subCategories?.map((sc: any) => (
                            <div key={sc.categoryId}
                                 className={sc.isActive ? styles.filterItem : styles.filterItemDisable}
                                 onClick={() => sc.isActive && onChange(sc.categoryId || "", "subCat", sc.topCategoryId)}>
                                <Checkbox
                                    checked={sc.categoryId === newSelectedFilters.subCategoryFilter?.subCategoryId}
                                    disabled={!sc.isActive}
                                />
                                {sc.name}
                            </div>))
                        }
                        </div>
                    </>
                )
            case "Поставщики":
                return (
                  <>
                      <div>
                          <div className={styles.filterTitle}>
                              Поставщики
                          </div>
                          <SearchInFilter initialText={'Поиск'} changeHandler={setSearchSuppliers}
                                          value={searchSuppliers}/>
                          {searchSuppliersByName(filtersData.suppliers, searchSuppliers)?.length !== 0
                            ? (sortArrayByActive(searchSuppliersByName(filtersData.suppliers, searchSuppliers))?.slice(startIndexSuppliers,endIndexSuppliers).map((s: any) => (
                            <div key={s.id}
                                 className={s.isActive ? styles.filterItem : styles.filterItemDisable}
                                 onClick={() => s.isActive && onChange(s.id || "", "supplier")}
                            >
                                <Checkbox
                                  checked={newSelectedFilters.sellersIds?.includes(s.id)}
                                  disabled={!s.isActive}
                                />
                                {s.displayedName}
                            </div>))) : (
                            <div className={styles.searchText}>Ничего не найдено</div>
                          )
                          }
                      </div>
                      <div style={{paddingBottom: 20}}>
                          <Pagination
                            current={currentPageSuppliers}
                            pageSize={ITEMS_PER_PAGE}
                            total={filtersData.suppliers?.length}
                            onChange={setCurrentPageSuppliers}
                            align={"center"}
                            size={"small"}
                          />
                      </div>
                  </>
                )
            case "Бренды":
                return (
                  <>
                      <div>
                          <div className={styles.filterTitle}>
                              Бренды
                          </div>
                          <SearchInFilter initialText={'Поиск'} changeHandler={setSearchBrands} value={searchBrands}/>
                          {searchBrandsByName(filtersData?.brands, searchBrands)?.length !== 0 ? (
                            sortArrayByActive(searchBrandsByName(filtersData?.brands, searchBrands))?.slice(startIndexBrands,endIndexBrands).map((b: any) => (
                            <div key={b.brandId}
                                 className={b.isActive ? styles.filterItem : styles.filterItemDisable}
                                 onClick={() => b.isActive && onChange(b.brandId || "", "brand")}
                            >
                                <Checkbox
                                  checked={newSelectedFilters.brands?.includes(b.brandId)}
                                  disabled={!b.isActive}
                                />
                                {b.brandName}
                            </div>
                          ))
                        ) : (
                          <div className={styles.searchText}>Ничего не найдено</div>
                        )}
                        </div>
                            <div style={{paddingBottom: 20}}>
                                <Pagination
                                  current={currentPageBrands}
                                  pageSize={ITEMS_PER_PAGE}
                                  total={filtersData?.brands?.length}
                                  onChange={setCurrentPageBrands}
                                  align={"center"}
                                  size={"small"}
                                />
                            </div>
                            
                       
                    </>
                )
            case "TopCatAttr":
                let topFilterOpt = selectedTopCategory?.filters?.find((fa) => fa.id === currentFilterAttr)

                return (
                    <>
                        <div>
                        <div className={styles.filterTitle}>
                            {topFilterOpt?.name}
                        </div>
                        {topFilterOpt?.filterAttributes?.map((fa: any) => (
                            <div key={fa.id}
                                 className={styles.filterItem}
                                 onClick={() => onChange(fa.id || "", "topCatOpt")}
                            >
                                <Checkbox
                                    checked={newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id)}
                                />
                                {fa.name}
                            </div>))
                        }
                        </div>
                    </>
                )

            case "SubCatAttr":
                let subFilterOpt = selectedSubCategory?.filters?.find((fa) => fa.id === currentFilterAttr)

                return (
                    <>
                        <div>
                        <div className={styles.filterTitle}>
                            {subFilterOpt?.name}
                        </div>
                        {subFilterOpt?.filterAttributes?.map((fa: any) => (
                            <div key={fa.id}
                                 className={styles.filterItem}
                                 onClick={() => onChange(fa.id || "", "subCatOpt")}
                            >
                                <Checkbox
                                    checked={newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)}
                                />
                                {fa.name}
                            </div>))
                        }
                        </div>
                    </>
                )

            default:
                return <div>Выберите категорию фильтра</div>;
        }
    };

    return (
        <>
            <div className={styles.filterTopBlock}>
                <button
                    className={styles.filterButton}
                    onClick={() => setFilterOpen(!isFilterOpen)}
                >
                    Фильтр
                    {numberOfFilters > 0 ?
                        <div className={styles.filterButtonIndicator}>{numberOfFilters}</div>
                        : <></>
                    }
                </button>

                <div className={styles.viewContainer}>
                    <div className={styles.view}>
                        <div className={styles.viewItem + (selectedView == 0 ? " " + styles.viewItemActive : "")}
                             onClick={() => onChangeView(0)}
                        >
                            {/*пришлось так сделать, потом по нормальному сделаю. нужно перелопатить всю структуру проекта, чтобы svg прокинуть правильно*/}
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.424528" y="0.424528" width="5.46684" height="5.46684" rx="0.575472"
                                      stroke="#333333" strokeWidth="0.849057"/>
                                <rect x="9.10812" y="0.424528" width="5.46684" height="5.46684" rx="0.575472"
                                      stroke="#333333" strokeWidth="0.849057"/>
                                <rect x="0.424528" y="9.10861" width="5.46684" height="5.46684" rx="0.575472"
                                      stroke="#333333" strokeWidth="0.849057"/>
                                <rect x="9.10812" y="9.10861" width="5.46684" height="5.46684" rx="0.575472"
                                      stroke="#333333" strokeWidth="0.849057"/>
                            </svg>
                            Карточками
                        </div>
                        <div className={styles.viewItem + (selectedView == 1 ? " " + styles.viewItemActive : "")}
                             onClick={() => onChangeView(1)}
                        >
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="14" height="14" rx="0.5" stroke="#333333"/>
                                <line y1="5.5" x2="14" y2="5.5" stroke="#333333"/>
                                <line y1="9.5" x2="14" y2="9.5" stroke="#333333"/>
                            </svg>
                            Списком
                        </div>
                    </div>
                </div>
            </div>

            <div
                id={"filterPopup"}
                className={`${styles.filterPopup} ${
                    isFilterOpen ? styles.open : ""
                }`}
            >
                <div className={styles.filterHeader}>
                    <CustomNavigateHeader title={"Фильтр"} onClick={() => setFilterOpen(false)} type={"close"}/>
                </div>
                <div className={styles.filterContentContainer}>
                    <div className={styles.filterSidebar}>
                        <button
                            className={`${styles.menuButton} 
                            ${currentFilter === "Категория" ? styles.active : ""} 
                            ${selectedTopCategory? styles.menuButtonSel : ""}`}
                            onClick={() => setCurrentFilter("Категория")}
                        >
                            {selectedTopCategory? selectedTopCategory.name : "Категория"}
                        </button>
                        {filtersData.subCategories && filtersData.subCategories.length > 0 ?
                            <button
                                className={`${styles.menuButton} 
                                ${currentFilter === "Подкатегория" ? styles.active : ""}
                                ${selectedSubCategory? styles.menuButtonSel : ""}`}
                                onClick={() => setCurrentFilter("Подкатегория")}
                            >
                                {selectedSubCategory? selectedSubCategory.name : "Подкатегория"}
                            </button>
                            : <></>
                        }
                        <button
                            className={`${styles.menuButton} 
                            ${currentFilter === "Поставщики" ? styles.active : ""}
                            ${newSelectedFilters.sellersIds && newSelectedFilters.sellersIds.length > 0 ? styles.menuButtonSel : ""}`}
                            onClick={() => setCurrentFilter("Поставщики")}
                        >
                            Поставщики
                            {newSelectedFilters.sellersIds && newSelectedFilters.sellersIds.length > 0 ?
                                <div className={styles.menuButtonIndicator}>{newSelectedFilters.sellersIds.length}</div>
                                : <></>
                            }
                        </button>
                        <button
                            className={`${styles.menuButton} 
                            ${currentFilter === "Бренды" ? styles.active : ""}
                            ${newSelectedFilters.brands && newSelectedFilters.brands.length > 0 ? styles.menuButtonSel : ""}`}
                            onClick={() => setCurrentFilter("Бренды")}
                        >
                            Бренды
                            {newSelectedFilters.brands && newSelectedFilters.brands.length > 0 ?
                                <div className={styles.menuButtonIndicator}>{newSelectedFilters.brands.length}</div>
                                : <></>
                            }
                        </button>

                        {selectedTopCategory && selectedTopCategory.filters && selectedTopCategory.filters?.length > 0 &&
                            selectedTopCategory.filters.map(f => {
                                if (f.filterAttributes && f.filterAttributes.length > 0) {
                                    return (
                                        <button
                                            key={f.id}
                                            className={`${styles.menuButton} 
                                            ${currentFilter === "TopCatAttr" && topCatAttrName === f.name  ? styles.active : ""}
                                            ${f.filterAttributes.some((fa) => newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id))? 
                                                styles.menuButtonSel : ""}
                                            `}
                                            onClick={() => {
                                                setCurrentFilterAttr(f.id);
                                                setCurrentFilter("TopCatAttr")
                                                setTopCatAttrName(f.name)
                                            }}
                                        >
                                            {f.name}
                                            {f.filterAttributes.some((fa) => newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id)) ?
                                                <div className={styles.menuButtonIndicator}>
                                                    {f.filterAttributes.filter((fa) => newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id)).length}
                                                </div>
                                                : <></>
                                            }
                                        </button>
                                    )
                                }
                            })
                        }

                        {selectedSubCategory && selectedSubCategory.filters && selectedSubCategory.filters?.length > 0 &&
                            selectedSubCategory.filters.map(f => {
                                if (f.filterAttributes && f.filterAttributes.length > 0) {
                                    return (
                                        <button
                                            key={f.id}
                                            className={`${styles.menuButton} 
                                            ${currentFilter === "SubCatAttr" && subCatAttrName === f.name ? styles.active : ""}
                                            ${f.filterAttributes.some((fa) => newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)) ?
                                                styles.menuButtonSel : ""}
                                            `}
                                            onClick={() => {
                                                setCurrentFilterAttr(f.id);
                                                setCurrentFilter("SubCatAttr")
                                                setSubCatAttrName(f.name)
                                            }}
                                        >
                                            {f.name}
                                            {f.filterAttributes.some((fa) => newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)) ?
                                                <div className={styles.menuButtonIndicator}>
                                                    {f.filterAttributes.filter((fa) => newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)).length}
                                                </div>
                                                : <></>
                                            }
                                        </button>
                                    )
                                }
                            })
                        }

                    </div>
                    <div className={styles.filterContent} ref={divRef}>
                        {renderFilterItems()}
                        {loading && <BeatLoader className={styles.filterLoader}/>}
                    </div>
                </div>

                <div className={styles.footer}>
                    <button
                        className={styles.clearButton}
                        onClick={() => {
                            clearFilters();
                            setFilterOpen(false)
                            setNewSelectedFilters({});
                            onChangeFilter({})
                        }}
                    >
                        Очистить
                    </button>
                    <button
                        className={styles.applyButton}
                        onClick={() => {
                            setFilterOpen(false)
                            applyFilters(newSelectedFilters)
                        }}
                    >
                        Применить
                    </button>
                </div>
            </div>

        </>
    );
};

export default MobileFilterCatalog;
