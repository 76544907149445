import Login from './components/PublicPages/Login/Login';
import Missing from './components/PublicPages/MissingPage/Missing';
import Unauthorized from './components/PublicPages/Unauthorized/Unauthorized';
import RequireAuth from './components/Auth/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import Cart from "./components/ProtectedPages/Customer/Cart/Cart";
import Buyer from "./components/PublicPages/Register/Buyer/Buyer";
import Seller from "./components/PublicPages/Register/Seller/Seller";
import Register from "./components/PublicPages/Register/Register"
import OrderFormation from "./components/ProtectedPages/Customer/OrderFormation/OrderFormation";
import React, {useEffect} from "react";
import Customer from "./components/ProtectedPages/Customer/Customer";
import Customers from "./components/ProtectedPages/Admin/Customers/Customers";
import CatalogIndex from "./components/PublicPages/CatalogOld/CatalogIndex";
import SubCatPage from "./components/PublicPages/CatalogOld/SubCatPage";
import CatPage from "./components/PublicPages/CatalogOld/CatPage";
import Profile from "./components/ProtectedPages/Customer/Profile/Profile";
import Favorites from "./components/ProtectedPages/Customer/Favorites/Favorites";
import SuppliersPage from "./components/ProtectedPages/Customer/SuppliersPage/SuppliersPage";
import Coupons from "./components/ProtectedPages/Customer/Coupons/Coupons";
import MyOrdersActive from "./components/ProtectedPages/Customer/MyOrders/MyOrdersActive/MyOrdersActive";
import MyOrderPage from "./components/ProtectedPages/Customer/MyOrders/MyOrderPage/MyOrderPage";
import {Logout} from "./components/PublicPages/Logout/Logout";
import ProductPage from "./components/PublicPages/ProductPage/ProductPage";
import Chat from "./components/ProtectedPages/Customer/Chat/Chat";
import SupplierProfile from "./components/ProtectedPages/Supplier/Profile/SupplierProfile";
import SelectProduct from "./components/ProtectedPages/Supplier/SelectProduct/SelectProduct";
import {SpaRoutes} from "./Routes/spaRoutes";
import Home from "./components/Home/Home";
import CatalogSupplier from "./components/ProtectedPages/Supplier/Catalog/CatalogSupplier";
import ProductDetails from "./components/ProtectedPages/Supplier/ProductDetails/ProductDetails";
import SupplierProducts from "./components/ProtectedPages/Supplier/MyProducts/SupplierProducts";
import {AboutUs} from "./components/PublicPages/AboutUs/AboutUs";
import Bonuses from "./components/ProtectedPages/Supplier/Bonuses/Bonuses";
import BonusesTransactionHistory from "./components/ProtectedPages/Supplier/Bonuses/BonusesTransactionHistory";
import SupplierMagazines from "./components/ProtectedPages/Supplier/Magazines/SupplierMagazines";
import MagazineEdit from "./components/ProtectedPages/Supplier/Magazines/MagazineEdit";
import SupplierChat from "./components/ProtectedPages/Supplier/Chat/SupplierChat";
import {CustomerDialogPage} from "./components/ProtectedPages/Customer/Chat/CustomerDialogPage";
import MyOrderPayment from "./components/ProtectedPages/Customer/MyOrders/MyOrderPayment/MyOrderPayment";
import Training from "./components/ProtectedPages/Supplier/Training/Training";
import SupplierOrderPage from "./components/ProtectedPages/Supplier/SupplierOrderPage/SupplierOrderPage";
import AddUpdateTraining from "./components/ProtectedPages/Supplier/Training/AddTraining/AddUpdateTraining";
import './App.css'
import Admin from "./components/ProtectedPages/Admin/Admin";
import {UnprocessedProducts} from "./components/ProtectedPages/Admin/UnprocessedProducts/UnprocessedProducts";
import AdminHome from "./components/Home/AdminHome/AdminHome";
import Suppliers from "./components/ProtectedPages/Admin/Suppliers/Suppliers";
import RootPreview from "./components/ProtectedPages/Admin/RootCardsPreview/RootPreview";
import Categories from "./components/ProtectedPages/Admin/Categories/Categories";
import AdminProductDetails from "./components/ProtectedPages/Admin/ProductDetails/AdminProductDetails";
import SupplierCardsHistory
    from "./components/ProtectedPages/Supplier/CardsPaymentHistory/SupplierCardsHistoryMain";
import AdminCardsHistory
    from "./components/ProtectedPages/Admin/AllSuppliersCardsPayHistory/AllSuppliersCardsPayHistory";
import MainSettings from "./components/ProtectedPages/Admin/MainSettings/MainSettings";
import SupplierQP from "./components/ProtectedPages/Admin/SupplierQuestionPage/SupplierQuestionPage";
import AdminChat from './components/ProtectedPages/Admin/AdminChat/AdminChat';
import Filter from "./components/ProtectedPages/Admin/Filter/Filter";
import Filter_edit from "./components/ProtectedPages/Admin/Filter/Filter_edit";
import EmailConfirm from "./components/PublicPages/EmailConfirm/EmailConfirm";
import HistoryViewed from "./components/ProtectedPages/Customer/HistoryViewed/HistoryViewed";
import MyOrdersClosed from "./components/ProtectedPages/Customer/MyOrders/MyOrdersClosed/MyOrdersClosed";
import BuyerCompany from "./components/PublicPages/Register/BuyerCompany/BuyerComp";
import ReservationReklama from "./components/ProtectedPages/Supplier/ReservationReklama/ReservationReklama";
import SupplierReklama from "./components/ProtectedPages/Supplier/SupplierReklama/SupplierReklama";
import ModerationReklama from "./components/ProtectedPages/Admin/ModerationReklama/ModerationReklama";
import AdminReservationReklama from "./components/ProtectedPages/Admin/AdminReservationReklama/AdminReservationReklama";
import RelatedProducts from "./components/PublicPages/CatalogOld/RelatedProducts";
import Orders from "./components/ProtectedPages/Supplier/Orders/Orders";
import SellerOrderPaymentOption
    from "./components/ProtectedPages/Supplier/Invoices/SellerOrderPaymentOption/SellerOrderPaymentOption";
import Clients from "./components/ProtectedPages/Supplier/Clients/Clients";
import CustomerBonuses from "./components/ProtectedPages/Customer/CustomerBonuses/CustomerBonuses";
import Catalog from "./components/PublicPages/Catalog/Catalog";


const ROLES = {
    'Customer': 'Customer',
    'Supplier': 'Dealer',
    'Admin': 'Admin'
}

function App() {

    return (
        <div className={'App-div'}>
            <Routes>
                {/* public routes */}
                <Route path="/" element={<Home/>}/>
                <Route path={SpaRoutes.LOGIN} element={<Login/>}/>
                <Route path={SpaRoutes.REGISTER} element={<Register/>}/>
                <Route path={SpaRoutes.LOGOUT} element={<Logout/>}/>
                <Route path={SpaRoutes.BUYER} element={<Buyer/>}/>
                <Route path={SpaRoutes.BUYER_COMPANY} element={<BuyerCompany/>}/>
                <Route path={SpaRoutes.SELLER} element={<Seller/>}/>
                <Route path={SpaRoutes.UNAUTHORIZED} element={<Unauthorized/>}/>
                <Route path={SpaRoutes.CATALOG_INDEX + "/:search"} element={<CatalogIndex/>}/>
                <Route path={SpaRoutes.CATALOG_INDEX} element={<CatalogIndex/>}/>
                <Route path={SpaRoutes.SUB_CATEGORY + "/:id/:search"} element={<SubCatPage/>}/>
                <Route path={SpaRoutes.CATEGORY + "/:id/:search"} element={<CatPage/>}/>
                <Route path={SpaRoutes.RELATED_PRODUCTS + "/:id"} element={<RelatedProducts/>}/>
                <Route path={SpaRoutes.PRODUCT + "/:id"} element={<ProductPage/>}/>
                <Route path={SpaRoutes.ABOUT_US} element={<AboutUs/>}/>
                <Route path={SpaRoutes.EMAIL_CONFIRM + "/:userId/:code/:page"} element={<EmailConfirm/>}/>
                <Route path={SpaRoutes.CATALOG} element={<Catalog />} />


                <Route element={<RequireAuth allowedRoles={[ROLES.Customer]}/>}>
                    <Route path={SpaRoutes.CART} element={<Cart/>}/>
                    <Route path={SpaRoutes.ORDER_FORMATION} element={<OrderFormation/>}/>
                    <Route path={SpaRoutes.SUPPLIER_PAGE} element={<SuppliersPage/>}/>
                    <Route path={SpaRoutes.PROFILE} element={<Profile/>}/>
                    <Route path={SpaRoutes.FAVORITES} element={<Favorites/>}/>
                    <Route path={SpaRoutes.HISTORYVIEWED} element={<HistoryViewed/>}/>
                    <Route path={SpaRoutes.MY_ORDERS_ACTIVE} element={<MyOrdersActive/>}/>
                    <Route path={SpaRoutes.MY_ORDERS_CLOSED} element={<MyOrdersClosed/>}/>
                    <Route path={SpaRoutes.MYORDER + "/:id"} element={<MyOrderPage/>}/>
                    <Route path={SpaRoutes.CUSTOMER_CHAT_LIST} element={<Chat/>}/>
                    <Route path={SpaRoutes.CHAT_DIALOG_PAGE + "/:id/:type/:infoId"} element={<CustomerDialogPage/>}/>

                    <Route path={SpaRoutes.MYORDER_PAYMENT + "/:id/:price/:paymentOption/:isPayed"} element={<MyOrderPayment/>}/>

                    <Route path={SpaRoutes.Customer.Bonuses} element={<CustomerBonuses/>}/>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Supplier]}/>}>
                    <Route path={SpaRoutes.SUP_CATALOG} element={<CatalogSupplier/>}/>
                    <Route path={SpaRoutes.Seller.OrderPayment + "/:id/:price/:payed/:paymentOption"} element={<SellerOrderPaymentOption/>}/>
                    <Route path={SpaRoutes.SUPPLIER_PROFILE} element={<SupplierProfile/>}/>

                    <Route path={`${SpaRoutes.Seller.Orders.Orders}/:status/:destinationOption`} element={<Orders/>} />
                    <Route path={`${SpaRoutes.Seller.Orders.Orders}/:status`} element={<Orders/>} />

                    <Route path={SpaRoutes.ADDTRAINING + "/:eventId"} element={<AddUpdateTraining/>}/>
                    <Route path={SpaRoutes.SUPPLIER_CHAT + "/:id/:type/:infoId"} element={<SupplierChat/>}/>
                    <Route path={SpaRoutes.SUPPLIER_CHAT} element={<SupplierChat/>}/>

                    <Route path={SpaRoutes.SUP_ORDER_PAGE + "/:id"} element={<SupplierOrderPage/>}/>
                    <Route path={SpaRoutes.PRODUCT_DETAILS + "/:rootId" + "/:productId" + "/:questionId"}
                           element={<ProductDetails/>}/>
                    <Route path={SpaRoutes.SUPPLIER_PRODUCTS} element={<SupplierProducts/>}/>
                    <Route path={SpaRoutes.SELECT_PRODUCT} element={<SelectProduct/>}/>
                    <Route path={SpaRoutes.SUP_MAGAZINE} element={<SupplierMagazines/>}/>
                    <Route path={SpaRoutes.SUP_MAGAZINE_EDIT + "/:id" + "/:connectedId"} element={<MagazineEdit/>}/>

                    <Route path={SpaRoutes.SUP_CARDS_PAYMENT_HISTORY} element={<SupplierCardsHistory/>}/>

                    <Route path={SpaRoutes.SUPPLIER_RESERVATION_REKLAMA} element={<ReservationReklama/>}/>
                    <Route path={SpaRoutes.SUPPLIER_REKLAMA + "/create"} element={<SupplierReklama isCreate={true}/>}/>
                    <Route path={SpaRoutes.SUPPLIER_REKLAMA} element={<SupplierReklama/>}/>

                    <Route path={SpaRoutes.Seller.Clients} element={<Clients/>}/>
                    <Route path={SpaRoutes.BONUSES} element={<Bonuses/>}/>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Supplier, ROLES.Customer]}/>}>

                    <Route path={SpaRoutes.BONUSES_TRANSACTION_HISTORY} element={<BonusesTransactionHistory/>}/>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Supplier, ROLES.Customer, ROLES.Admin]}/>}>
                    <Route path={SpaRoutes.TRAINING} element={<Training/>}/>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]}/>}>
                    <Route path={SpaRoutes.ADMIN_PRODUCT_DETAILS + "/:rootId"} element={<AdminProductDetails/>}/>
                    <Route path={SpaRoutes.ADMIN_SUPPLIERS} element={<Suppliers/>}/>
                    <Route path={SpaRoutes.ADMIN_SUPPLIER + "/:supplierId"} element={<SupplierProfile/>}/>
                    <Route path={SpaRoutes.ADMIN_SUPPLIER_MAGAZINE + "/:supplierId"} element={<SupplierMagazines/>}/>
                    <Route path={SpaRoutes.ADMIN_BUYERS} element={<Customers/>}/>
                    <Route path={SpaRoutes.ADMIN_BUYER + "/:userId"} element={<Profile/>}/>
                    <Route path={SpaRoutes.ADMIN_CATEGORIES} element={<Categories/>}/>
                    <Route path={SpaRoutes.UNPROCESSED_PRODUCTS} element={<UnprocessedProducts/>}/>
                    <Route path={SpaRoutes.ADMIN_ROOT_PREVIEW + "/:deleted"} element={<RootPreview/>}/>
                    <Route path={SpaRoutes.ADMIN_MAIN_SETTINGS} element={<MainSettings/>}/>
                    <Route path={SpaRoutes.ADMIN_SUPPLIER_QP + "/:questionId"} element={<SupplierQP/>}/>
                    <Route path={SpaRoutes.ADMIN_CARDS_PAYMENT_HISTORY} element={<AdminCardsHistory/>}/>
                    <Route path={SpaRoutes.ADMIN_CHAT + "/:id/:type/:infoId"} element={<AdminChat/>}/>
                    <Route path={SpaRoutes.ADMIN_CHAT} element={<AdminChat/>}/>
                    <Route path={SpaRoutes.CATALOG_INDEX} element={<CatalogIndex/>}/>
                    <Route path={SpaRoutes.FILTERS + "/:topCat/:catId"} element={<Filter/>}/>
                    <Route path={SpaRoutes.FILTER_EDIT + "/:id"} element={<Filter_edit/>}/>
                    <Route path={SpaRoutes.ADMIN_MODERATION_REKLAMA} element={<ModerationReklama/>}/>
                    <Route path={SpaRoutes.ADMIN_REKLAMA} element={<AdminReservationReklama/>}/>

                </Route>

                {/* catch all */}
                <Route path={SpaRoutes.IN_PROGRESS} element={<Missing isComingSoon={true}/>}/>
                <Route path="*" element={<Missing/>}/>
            </Routes>
        </div>
    );
}
export default App;
