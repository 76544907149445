import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import {Context} from "../../../../../index";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import styles from "../SuppliersPage.module.scss";
import {BsChatDots, BsCheck, BsClipboard, BsClipboardFill, BsCopy, BsInfoCircle, BsMarkdown} from "react-icons/bs";

interface Props {
    company: any,
    numberOfIndex: number
}

export default function SuppliersCompanyItem({company, numberOfIndex}:Props) {
    const {store} = useContext(Context);
    const copyPhoneNumber = () => {
        navigator.clipboard.writeText(company.phone);
        const notificationElement = document.getElementsByClassName(styles.wrapperNotification)[0] as HTMLElement;
        notificationElement.style.opacity = "100";
        setTimeout(() => {
            notificationElement.style.opacity = "0";
        }, 1500)
    }

    return (
        <>
            <div className={styles.wrapperNotification}>
                <BsCheck
                    size={25}
                    color="white"
                    style={{paddingRight: "2", marginTop: "0"}}
                />
                <span className={`${styles.copyNotification}`}>
                Номер скопирован!
                </span>

            </div>

            <div key={company.displayedName} className={styles.supData}>
                <div className={styles.supInfo} style={{width: '100%'}}>
                    <span className={styles.supIndex}>{numberOfIndex}</span>
                    <span className={styles.supCompanyName}>{company.displayedName}</span>

                    <span className={styles.supTelephone}>{company.phone}
                        <BsClipboardFill
                            size={15}
                            style={{
                                color: "gray",
                                cursor: "pointer",
                                transition: "color 0.3s",
                                marginLeft: 5,
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.color = "black")}
                            onMouseLeave={(e) => (e.currentTarget.style.color = "gray")}
                            onClick={() => copyPhoneNumber()}
                        />
                    </span>

                </div>

                <div className={styles.supLinks} style={{width: '100%'}}>
                    {/*ССЫЛКА НА ИНФОРМАЦИЮ О ПОСТАВЩИКЕ(В БУДУЩЕМ)*/}
                    {/*<Link*/}
                    {/*    to={`/chat/${company.id}/1/0`}*/}
                    {/*    className={styles.linkWrapper}*/}
                    {/*>*/}
                    {/*    {store.roles.find((role) => role.includes("Customer")) && (*/}
                    {/*        <span className={`${styles.supInfo} ${styles.supButton}`}>*/}
                    {/*      Информация о поставщике*/}
                    {/*      <BsInfoCircle*/}
                    {/*          size={25}*/}
                    {/*          className={styles.infoIcon}*/}
                    {/*      />*/}
                    {/*    </span>*/}

                    {/*    )}*/}
                    {/*</Link>*/}
                    {/*ССЫЛКА НА ИНФОРМАЦИЮ О ПОСТАВЩИКЕ(В БУДУЩЕМ)*/}

                    <Link
                        to={SpaRoutes.CHAT_DIALOG_PAGE + `/:${company.id}/:1/:0`}
                        className={styles.linkWrapper}
                    >
                        {(store.roles.find((role) => role.includes('Customer'))) &&
                            <span className={`${styles.supChat} ${styles.supButton}`}>
                            Чат
                              <BsChatDots
                                  size={25}
                                  className={styles.infoIcon}
                              />
                        </span>
                        }
                    </Link>
                </div>
            </div>
        </>
    )
}

