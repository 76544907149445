import {Link, useNavigate} from "react-router-dom";
import {IRegion} from "../../../models/models";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import AuthenticateService from "../../../services/AuthenticateService";
import HomeService from "../../../services/HomeService";
import RegionSelector from "../../customUI/RegionSelector/RegionSelector";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../../index";
import styles from "./MenuCatalogSticky.module.scss"

interface IUserInfo {
	url?:string;
	name?:string;
	regionName?:string;
}

export const MenuCatalogSticky = () => {
	const {store} = useContext(Context)
	const [userInfo, setUserInfo] = useState<IUserInfo>({url:undefined,name:undefined})
	const [regions, setRegions] = useState<IRegion[]>([])
	
	useEffect(()=>{
		AuthenticateService.userInfo()
			.then(data => {
				setUserInfo(data.data);
		})
		HomeService.GetRegions()
			.then((resp) => {
				setRegions(resp.data.regions);
			});
	},[store.regionId])
	
	
	return(
		<div className={styles.sticky}>
			<div className={styles.container}>
					<div className={styles.location}>
						<img src="/Pictures/Локация.svg"/>
						<RegionSelector regions={regions} name={userInfo?.regionName ? userInfo.regionName : "Пермский край"}/>
					</div>
					{store.isAuth && <div className={styles.bonuses}>{store.user.bonuses} Б</div>}
				</div>
		</div>
	)
}