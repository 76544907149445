import React, {useEffect, useRef, useState} from "react";
import {IDropdownOption} from "../../../../../../models/models";
import styles from "./SuppliersDropdown.module.scss";
import {event} from "jquery";
import useOnClickOutside from "../../../../../../hooks/useOnClickOutside";
import {BsClipboardFill, BsGeoAltFill} from "react-icons/bs";

interface Interface {
    options: IDropdownOption[];
    value?:string | IDropdownOption;
    valueSetter:(val:any)=>any;
    onChangeHandler:(val:any)=>any;
}

const SuppliersDropdown = ({options,value,valueSetter,onChangeHandler}:Interface) => {
    const supDropdownDisplay = useRef(null)
    const [hideMe, setHideMe] = useState("true");
    const listRef = useRef(null);

    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => setHideMe("true"));

    const dropdownHandler = (val:any)=> {
        setHideMe("true");
        if (val.key)
            if (val.key != "no") {
                valueSetter(val.name)
                onChangeHandler(val.key);
            }
    }
    const clickHandler = (event: any) => {
        Array.prototype.forEach.call(supDropdownDisplay, function (item) {
            if (event?.currentTarget.identifier != item && item.dataset["hideme"] === "false") {
                item.click();
            }
        })
        if (options.length > 0) {
            hideMe == "true" ? setHideMe("false") : setHideMe("true");
        } else {
            setHideMe("true");
        }
    }

    const ListDropdown = options.map((item) => {
        return (
            <div
                className={`${styles.supDropdownItemName}${item.isDisable? styles.supDropdownItemNameDisable:""}`}
                key={item.name}
                onClick={() => item.isDisable? undefined :dropdownHandler(item)}
            >
                {item.name}
            </div>
        )
    })
    useEffect(()=>{
        console.log('hideMe:',hideMe);
    },[hideMe])
    return (
        <div className={styles.supDropdownInternalContainer} ref={ref}>
            <div className={styles.supDropdownDisplay} onClick={clickHandler} data-hide={hideMe} data-size='small' ref={supDropdownDisplay}>
                <BsGeoAltFill className={styles.supDropdownLocation}
                    size={15}
                    style={{
                    color: "gray",
                    cursor: "pointer",
                    transition: "color 0.3s",
                    }}
                />
                {/*<img src="/Pictures/location.svg" className={styles.supDropdownLocation} alt=""/>*/}
                <p className={styles.supDropdownDisplayText}>
                    {(typeof value === "string") ? value : value?.name}
                </p>
                <img className={styles.supDropDownImage}
                     data-hide={hideMe}
                     src={"/Pictures/dropDownIcon.svg"}
                     alt='Раскрыть'
                />
            </div>
            <div ref={listRef} className={styles.supDropdownListDiv} data-hide={hideMe} data-size='small'>
                <div className={styles.supDropdownListDivContent}>
                    {ListDropdown}
                </div>
            </div>
        </div>
    )
}
export default SuppliersDropdown;