import React, {useContext, useEffect, useState} from "react";
import HomeService from "../../../../services/HomeService";
import {IRegion} from "../../../../models/Regions/Region";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {OverviewDealerResponse} from "../../../../models/response/OverviewDealerResponse";
import SuppliersCompanyList from "./SuppliersPageComponents/SuppliersCompanyList";
import SuppliersDropdown from "./SuppliersPageComponents/SuppliersDropdown/SuppliersDropdown";
import {IDropdownOption} from "../../../../models/models";
import {Context} from "../../../../index";
import {useCookies} from "react-cookie";
import styles from "./SuppliersPage.module.scss";

export default function SuppliersPage() {
    const {store} = useContext(Context);
    const [arrayRegions, setArrayRegions] = useState<IRegion[]>([]);
    const [dropdownRegions, setDropdownRegions] = useState<IDropdownOption[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<IRegion>();
    const [dataOverviewDealer, setDataOverviewDealer] = useState<OverviewDealerResponse[]>([])

    const getRegions = () => {
        store.DataLoadingON()

        HomeService.GetRegionsForSellers()
            .then((r) => {
                setArrayRegions(r.data.regions)

                let bufArrayRegions = r.data.regions.map((item: { regionId: any; name: any; }) => ({
                    key: item.regionId,
                    name: item.name,
                }));
                setDropdownRegions(bufArrayRegions);

                if (r.data.regions.length > 0) getUserCity(r.data.regions)
            })
            .catch(err => {
                store.showDialog("Что-то пошло не так")
                store.DataLoadingOFF()
            })
    }

    const getUserCity = (allRegions: IRegion[]) => {
        HomeService.MyCity()
            .then((r) => {
                const currentRegion = allRegions.find(
                    (region) => String(r.data.regionId) === String(region.regionId)
                );

                if (currentRegion) {
                    getDataSuppliers(currentRegion.regionId)
                    setSelectedRegion(currentRegion);
                }
            })
            .catch(err => {
                store.showDialog("Что-то пошло не так")
                store.DataLoadingOFF()
            })

    }

    const getDataSuppliers = (regionId: string) => {
        store.DataLoadingON()
        HomeService.overviewDealer(regionId)
            .then((r) => {
                setDataOverviewDealer(r.data);
            })
            .catch(err => {
                store.showDialog("Что-то пошло не так")
            })
            .finally(() => store.DataLoadingOFF());
    }

    const handleRegionChange = (val: any) => {
        const regionId = val;
        const selectedRegion = arrayRegions?.find((region) => region.regionId === regionId);
        if (selectedRegion) {
            getDataSuppliers(selectedRegion.regionId)
            setSelectedRegion(selectedRegion);
        }
    }

    useEffect(() => {
        getRegions()
    }, []);

    return (
        <>{!store.isDataLoading && selectedRegion &&
            <>
                <CustomNavigateHeader
                    trashClickHandler={() => {
                    }}
                    title="Поставщики"
                    withTrash={false}
                />
                <SuppliersDropdown
                    options={dropdownRegions}
                    value={selectedRegion?.name}
                    onChangeHandler={handleRegionChange}
                    valueSetter={setSelectedRegion}
                />

                <div className={styles.suppliersCitiesWrapper}>
                    {dataOverviewDealer.length === 0 ?
                        <div className={styles.supNotFound}>
                            <img src="/Pictures/notFound.png" width={150} alt=""/>
                            <h2 className={styles.supTextError}>К сожалению в этом регионе еще нет поставщиков.</h2>

                        </div>
                        :
                        <SuppliersCompanyList dataOverviewDealer={dataOverviewDealer}/>
                    }
                </div>
            </>
        }
        </>
    )
}
