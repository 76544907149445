import $api from "../api/axios";
import {AxiosResponse} from "axios/index";
import {RegionsResponse} from "../models/response/RegionsResponse";
import {MyCity} from "../models/Regions/MyCity";
import {OverviewDealerResponse} from "../models/response/OverviewDealerResponse";
import {GetProductInfoResponse} from "../models/response/getProductInfoResponse";
const {ApiRoutes: { Home }} = require ("../Routes/apiRoutes");

export default class HomeService {
    static async GetRegions():Promise<AxiosResponse<RegionsResponse>> {
        return $api.get<RegionsResponse>(Home.REGIONS);
    }
    static async GetRegionsForSellers():Promise<AxiosResponse> {
        return $api.get<RegionsResponse>(Home.REGIONSFORSELLER);
    }
    static async MyCity():Promise<AxiosResponse>{
        return $api.get<MyCity>(Home.MY_CITY);
    }
    static async SetRegion(Info1:string):Promise<AxiosResponse<RegionsResponse>> {
        return $api.post(Home.SETREGION,{Info1:Info1});
    }
    static async overviewDealer(Info1:string):Promise<AxiosResponse<OverviewDealerResponse[]>>{
        return $api.post<OverviewDealerResponse[]>(Home.OVERVIEW_DEALER_REGION, {Info1})
    }
    static async getProductInfo(id:string):Promise<AxiosResponse<GetProductInfoResponse>>{
        return $api.post(Home.DETAIL_VIEW_PRODUCT, {Info1: String(id).slice(1)})
    }
    static async getKladrRegion(value:string):Promise<AxiosResponse>{
        return await $api.post(Home.KLADR, {Info1:value,Info2:"region"})
    }
    static async getKladrCity(value:string, regionId:string):Promise<AxiosResponse>{
        return await $api.post(Home.KLADR, {Info1:value,Info2:"city",Info3:regionId})
    }
    static async indexProduct():Promise<AxiosResponse>{
        return await $api.get(Home.INDEX_PRODUCT);
    }

    static async indexSlider():Promise<AxiosResponse>{
        return await $api.get(Home.INDEX_SLIDER);
    }

    static async indexReklama():Promise<AxiosResponse>{
        return await $api.get(Home.INDEX_REKLAMA);
    }

    static async catList(filterInfo:any):Promise<AxiosResponse>{
        return await $api.post(Home.CAT_LIST,{List1:filterInfo.selectedIds});
    }

    static async allCatalog(filterInfo:any):Promise<AxiosResponse>{
        return await $api.post(Home.ALL_CATALOG,{List1:filterInfo.selectedIds});
    }

    static async indexCat(id: string|undefined,filterInfo:any):Promise<AxiosResponse>{
        return await $api.post(Home.INDEX_CAT,{topCategoryId:id, List1:filterInfo.selectedIds});
    }

    static async catalog(id: string|undefined,filterInfo:any):Promise<AxiosResponse>{
        return await $api.post(Home.CATALOG,{Info1:id, List1:filterInfo.selectedIds});
    }
    static async getCategories() {
        return $api.get(Home.CATEGORIES);
    }

    static async getBaseInformation():Promise<AxiosResponse>{
        return $api.get(Home.GET_BASE_INFORMATION);
    }

    static getAllCategories() {
        return $api.get(Home.CATEGORIES_WITH_FILTER)
    }

    static async userName(id: string):Promise<AxiosResponse>{
        return await $api.post(Home.USERNAME,{Info1:id});
    }

    static async mainPageReklama():Promise<AxiosResponse>{
        return await $api.get(Home.MAIN_PAGE_REKLAMA);
    }
}

