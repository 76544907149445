import React, {useEffect, useState} from "react";
import {searchBrandsByName, searchSuppliersByName} from "../../../../functions/searchByName";
import {sortArrayByActive} from "../../../../functions/sortByActive";
import SearchInFilter from "../../../customUI/SearchInFilter/SearchInFilter";
import styles from "./DesktopFilterCatalog.module.scss";
import {
    IBrandsFilterData,
    ICatalogFilterData,
    ICatalogSelectedFilter, IFilterAttr, ISellersFilterData,
    ISubCategoryFilterData,
    ITopCategoryFilterData,
} from "../../../../models/Catalog/Catalog";
import { BeatLoader } from "react-spinners";
import { Checkbox } from "antd";

interface Props {
    selectedView: number;
    onChangeView: (view: number) => void;
    selectedFilters: ICatalogSelectedFilter;
    filtersData: ICatalogFilterData;
    loading: boolean;
    onChangeFilter: (newFilter: ICatalogSelectedFilter) => void;
    applyFilters: (newFilter: ICatalogSelectedFilter) => void;
    clearFilters: () => void;
}

const DesktopFilterCatalog = ({
                                  selectedView,
                                  onChangeView,
                                  selectedFilters,
                                  filtersData,
                                  loading,
                                  onChangeFilter,
                                  applyFilters,
                                  clearFilters,
                              }: Props) => {
    const [selectedTopCategory, setSelectedTopCategory] = useState<ITopCategoryFilterData>()
    const [selectedSubCategory, setSelectedSubCategory] = useState<ISubCategoryFilterData>()

    const [newSelectedFilters, setNewSelectedFilters] = useState(selectedFilters)
    const [numberOfFilters, setNumberOfFilter] = useState(0)
    
    const [searchSuppliers, setSearchSuppliers] = useState("")
    const [searchBrands, setSearchBrands] = useState("")
    
    useEffect(() => {
        setNewSelectedFilters(selectedFilters)
        let numberF = 0
        
        if(selectedFilters.topCategoryFilter){
            if(selectedFilters.topCategoryFilter.topCategoryId){
                numberF += 1
            }
            if(selectedFilters.topCategoryFilter.filterAttributes){
                numberF += selectedFilters.topCategoryFilter.filterAttributes.length
            }
        }
        
        if(selectedFilters.subCategoryFilter){
            if(selectedFilters.subCategoryFilter.subCategoryId){
                numberF += 1
            }
            if(selectedFilters.subCategoryFilter.filterAttributes){
                numberF += selectedFilters.subCategoryFilter.filterAttributes.length
            }
        }
        
        if(selectedFilters.sellersIds){
            numberF += selectedFilters.sellersIds.length
        }
        
        if(selectedFilters.brands){
            numberF += selectedFilters.brands.length
        }
        
        setNumberOfFilter(numberF)
        
    }, [selectedFilters.topCategoryFilter, selectedFilters.subCategoryFilter, selectedFilters.sellersIds, selectedFilters.brands]);

    useEffect(() => {
        if (newSelectedFilters && filtersData) {
            if (
                newSelectedFilters.topCategoryFilter &&
                filtersData.topCategories &&
                filtersData.topCategories.length > 0
            ) {
                setSelectedTopCategory(
                    filtersData.topCategories.find(
                        (tc) =>
                            tc.topCategoryId ===
                            newSelectedFilters.topCategoryFilter?.topCategoryId
                    )
                );
            }
            if (
                newSelectedFilters.subCategoryFilter &&
                filtersData.subCategories &&
                filtersData.subCategories.length > 0
            ) {
                setSelectedSubCategory(
                    filtersData.subCategories.find(
                        (sc) =>
                            sc.categoryId ===
                            newSelectedFilters.subCategoryFilter?.subCategoryId
                    )
                );
            }
        }
    }, [newSelectedFilters, filtersData]);

    const onChange = (value: string, type: string, topCatId?: string) => {
        let newFilters = { ...newSelectedFilters };

        switch (type) {
            case "topCat":
                newFilters.topCategoryFilter = {
                    ...newFilters.topCategoryFilter,
                    topCategoryId:
                        newFilters.topCategoryFilter?.topCategoryId === value
                            ? null
                            : value,
                    filterAttributes: null
                };
                newFilters.subCategoryFilter = null;
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "subCat":
                newFilters.topCategoryFilter = {
                    ...newFilters.topCategoryFilter,
                    topCategoryId:topCatId,
                    filterAttributes: newFilters.topCategoryFilter?.filterAttributes
                };
                newFilters.subCategoryFilter = {
                    ...newFilters.subCategoryFilter,
                    subCategoryId:
                        newFilters.subCategoryFilter?.subCategoryId === value
                            ? null
                            : value,
                    filterAttributes: null
                };
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "supplier":
                newFilters.sellersIds = newFilters.sellersIds?.includes(value)
                    ? newFilters.sellersIds.filter((id) => id !== value)
                    : [...newFilters.sellersIds??[], value];
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "brand":
                newFilters.brands = newFilters.brands?.includes(value)
                    ? newFilters.brands.filter((id) => id !== value)
                    : [...(newFilters.brands || []), value];
                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters)
                break;

            case "topCatOpt":
                newFilters.topCategoryFilter = {
                    ...newFilters.topCategoryFilter,
                    filterAttributes: newFilters.topCategoryFilter?.filterAttributes?.includes(value)
                        ? newFilters.topCategoryFilter.filterAttributes.filter((id) => id !== value)
                        : [...(newFilters.topCategoryFilter?.filterAttributes || []), value]
                };

                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters);
                break;

            case "subCatOpt":
                newFilters.subCategoryFilter = {
                    ...newFilters.subCategoryFilter,
                    filterAttributes: newFilters.subCategoryFilter?.filterAttributes?.includes(value)
                        ? newFilters.subCategoryFilter.filterAttributes.filter((id) => id !== value)
                        : [...(newFilters.subCategoryFilter?.filterAttributes || []), value]
                };

                onChangeFilter(newFilters);
                setNewSelectedFilters(newFilters);
                break;

            default:
                break;
        }
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.viewContainer}>
                Показать:
                <div className={styles.view}>
                    <div className={styles.viewItem + (selectedView==0? " " + styles.viewItemActive : "")}
                         onClick={() => onChangeView(0)}
                    >
                        {/*пришлось так сделать, потом по нормальному сделаю. нужно перелопатить всю структуру проекта, чтобы svg прокинуть правильно*/}
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.424528" y="0.424528" width="5.46684" height="5.46684" rx="0.575472"
                                  stroke="#333333" strokeWidth="0.849057"/>
                            <rect x="9.10812" y="0.424528" width="5.46684" height="5.46684" rx="0.575472"
                                  stroke="#333333" strokeWidth="0.849057"/>
                            <rect x="0.424528" y="9.10861" width="5.46684" height="5.46684" rx="0.575472"
                                  stroke="#333333" strokeWidth="0.849057"/>
                            <rect x="9.10812" y="9.10861" width="5.46684" height="5.46684" rx="0.575472"
                                  stroke="#333333" strokeWidth="0.849057"/>
                        </svg>
                        Карточками
                    </div>
                    <div className={styles.viewItem + (selectedView==1? " " + styles.viewItemActive : "")}
                         onClick={() => onChangeView(1)}
                    >
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="14" height="14" rx="0.5" stroke="#333333"/>
                            <line y1="5.5" x2="14" y2="5.5" stroke="#333333"/>
                            <line y1="9.5" x2="14" y2="9.5" stroke="#333333"/>
                        </svg>
                        Списком
                    </div>
                </div>
            </div>
            <div className={styles.filter}>

                {loading && <BeatLoader className={styles.filterLoader} />}
                
                <div className={styles.filterTitleContainer}>
                    <div className={styles.filterTitle}>Фильтр</div>
                    {numberOfFilters > 0 ?
                      <div className={styles.filterButtonIndicator}>{numberOfFilters}</div>
                      : <></>
                    }
                </div>
                
                
                <div className={styles.filterContent}>
                    {filtersData.topCategories && filtersData.topCategories.length > 0 ?
                        <div className={styles.filterItemsContainer}>
                            <div className={`${styles.filterItemsTitle}  ${selectedTopCategory? styles.filterItemsTitleSel : ""}`}>
                                Категория
                            </div>

                            <div className={styles.filterItems}>
                                {filtersData.topCategories.map((tc) => {
                                    return (
                                        <div key={tc.topCategoryId} className={tc.isActive? styles.filterItem : styles.filterItemDisable} onClick={() => tc.isActive&&onChange(tc.topCategoryId || "", "topCat")}>
                                            <Checkbox checked={tc.topCategoryId === newSelectedFilters.topCategoryFilter?.topCategoryId}
                                                      disabled={!tc.isActive}
                                            />
                                            {tc.name}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        :
                        <div className={styles.filterItemsContainer}>
                            <div className={styles.filterItemsTitle}>
                                Категория
                            </div>
                            <div className={styles.filterItems}>
                                {loading ? "Загрузка..." : "Нет категорий"}
                            </div>
                        </div>
                    }

                    {filtersData.subCategories && filtersData.subCategories.length > 0 ?
                        <div className={styles.filterItemsContainer}>
                            <div className={`${styles.filterItemsTitle}  ${selectedSubCategory ? styles.filterItemsTitleSel : ""}`}>
                                Подкатегория
                            </div>

                            <div className={styles.filterItems}>
                                {filtersData.subCategories.map((sc) => {
                                    return (
                                        <div key={sc.categoryId} className={sc.isActive ? styles.filterItem : styles.filterItemDisable}
                                             onClick={() => sc.isActive && onChange(sc.categoryId || "", "subCat", sc.topCategoryId)}>
                                            <Checkbox
                                                checked={sc.categoryId === newSelectedFilters.subCategoryFilter?.subCategoryId}
                                                disabled={!sc.isActive}
                                            />
                                            {sc.name}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        :
                        <></>
                        /*<div className={styles.filterItemsContainer}>
                            <div className={styles.filterItemsTitle}>
                                Подкатегория
                            </div>
                            <div className={styles.filterItems}>
                                {loading ? "Загрузка..." : "Нет подкатегорий"}
                            </div>
                        </div>*/
                    }

                    {filtersData.suppliers && filtersData.suppliers.length > 0 ?
                        <div className={styles.filterItemsContainer}>
                            <div className={styles.filterTitleContainer}>
                                <div className={`${styles.filterItemsTitle}
                                     ${newSelectedFilters.sellersIds && newSelectedFilters.sellersIds.length > 0 ? styles.filterItemsTitleSel : ""}`}>
                                    Поставщики
                                </div>
                                {newSelectedFilters.sellersIds && newSelectedFilters.sellersIds.length > 0 ?
                                  <div className={styles.filterIndicator}>{newSelectedFilters.sellersIds.length}</div>
                                  : <></>
                                }
                            </div>
                            <SearchInFilter initialText={'Поиск'} changeHandler={setSearchSuppliers} value={searchSuppliers}/>
                            {searchSuppliersByName(filtersData.suppliers, searchSuppliers)?.length !== 0
                              ?
                              (<div className={styles.filterItems}>
                                {sortArrayByActive(searchSuppliersByName(filtersData.suppliers, searchSuppliers)).map((s: ISellersFilterData) => {
                                    return (
                                      <div key={s.id}
                                           className={s.isActive ? styles.filterItem : styles.filterItemDisable}
                                           onClick={() => s.isActive && onChange(s.id || "", "supplier")}
                                      >
                                          <Checkbox
                                            checked={newSelectedFilters.sellersIds?.includes(s.id)}
                                            disabled={!s.isActive}
                                          />
                                          {s.displayedName}
                                      </div>
                                    )
                                })}
                            </div>)
                              :
                              (
                                <div className={styles.searchText}>Ничего не найдено</div>
                              )}
                        </div>
                        :
                        <div className={styles.filterItemsContainer}>
                            <div className={`${styles.filterItemsTitle}  ${newSelectedFilters.sellersIds && newSelectedFilters.sellersIds.length > 0 ? styles.filterItemsTitleSel : ""}`}>
                                Поставщики
                            </div>
                            <div className={styles.filterItems}>
                                {loading ? "Загрузка..." : "Нет поставщиков"}
                            </div>
                        </div>
                    }

                    {filtersData.brands && filtersData.brands.length > 0 ?
                        <div className={styles.filterItemsContainer}>
                            <div className={styles.filterTitleContainer}>
                                <div className={`${styles.filterItemsTitle}
                                     ${newSelectedFilters.brands && newSelectedFilters.brands.length > 0 ? styles.filterItemsTitleSel : ""}`}>
                                    Бренды
                                </div>
                                {newSelectedFilters.brands && newSelectedFilters.brands.length > 0 ?
                                  <div className={styles.filterIndicator}>{newSelectedFilters.brands.length}</div>
                                  : <></>
                                }
                            </div>
                            <SearchInFilter initialText={'Поиск'} changeHandler={setSearchBrands} value={searchBrands}/>
                            
                            {searchBrandsByName(filtersData.brands, searchBrands)?.length !== 0
                              ?
                                (<div className={styles.filterItems}>
                                {sortArrayByActive(searchBrandsByName(filtersData.brands, searchBrands)).map((b: IBrandsFilterData) => {
                                    return (
                                      <div key={b.brandId}
                                           className={b.isActive ? styles.filterItem : styles.filterItemDisable}
                                           onClick={() => b.isActive && onChange(b.brandId || "", "brand")}
                                      >
                                          <Checkbox
                                            checked={newSelectedFilters.brands?.includes(b.brandId)}
                                            disabled={!b.isActive}
                                          />
                                          {b.brandName}
                                      </div>
                                    )
                                })}
                            </div>)
                                :
                                ( <div className={styles.searchText}>Ничего не найдено</div>)}
                        </div>
                        :
                        <div className={styles.filterItemsContainer}>
                            <div className={styles.filterItemsTitle}>
                                Бренд
                            </div>
                            <div className={styles.filterItems}>
                                {loading ? "Загрузка..." : "Нет брендов"}
                            </div>
                        </div>
                    }

                    {selectedTopCategory && selectedTopCategory.filters && selectedTopCategory.filters?.length > 0 &&
                        selectedTopCategory.filters.map(f => {
                            if(f.filterAttributes && f.filterAttributes.length > 0) {
                                return (
                                    <div key={f.id} className={styles.filterItemsContainer}>
                                        <div className={styles.filterTitleContainer}>
                                            <div className={`${styles.filterItemsTitle}
                                                   ${f.filterAttributes.some((fa) => newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id))?
                                                    styles.filterItemsTitleSel : ""}`}>
                                                {f.name}
                                            </div>
                                            {f.filterAttributes.some((fa) => newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id)) ?
                                              <div className={styles.filterIndicator}>{f.filterAttributes.filter((fa) => newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id)).length}</div>
                                              : <></>
                                            }
                                        </div>
                                        <div className={styles.filterItems}>
                                            {sortArrayByActive(f.filterAttributes).map((fa: IFilterAttr) => {
                                                return (
                                                    <div key={fa.id}
                                                         className={styles.filterItem}
                                                         onClick={() => onChange(fa.id || "", "topCatOpt")}
                                                    >
                                                        <Checkbox
                                                            checked={newSelectedFilters.topCategoryFilter?.filterAttributes?.includes(fa.id)}
                                                        />
                                                        {fa.name}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                    {selectedSubCategory && selectedSubCategory.filters && selectedSubCategory.filters?.length > 0 &&
                        selectedSubCategory.filters.map(f => {
                            if (f.filterAttributes && f.filterAttributes.length > 0) {
                                return (
                                    <div key={f.id} className={styles.filterItemsContainer}>
                                        <div className={styles.filterTitleContainer}>
                                            <div className={`${styles.filterItemsTitle}
                                                 ${f.filterAttributes.some((fa) => newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)) ?
                                                  styles.filterItemsTitleSel : ""}`}>
                                                {f.name}
                                            </div>
                                            {f.filterAttributes.some((fa) => newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)) ?
                                              <div className={styles.filterIndicator}>{f.filterAttributes.filter((fa) => newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)).length}</div>
                                              : <></>
                                            }
                                        </div>
                                        <div className={styles.filterItems}>
                                            {sortArrayByActive(f.filterAttributes).map((fa: IFilterAttr) => {
                                                return (
                                                    <div key={fa.id}
                                                         className={styles.filterItem}
                                                         onClick={() => onChange(fa.id || "", "subCatOpt")}
                                                    >
                                                        <Checkbox
                                                            checked={newSelectedFilters.subCategoryFilter?.filterAttributes?.includes(fa.id)}
                                                        />
                                                        {fa.name}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                </div>
                <div className={styles.filterButtons}>
                    <button className={styles.filterButton} onClick={() => {
                        clearFilters()
                        setNewSelectedFilters({})
                        onChangeFilter({})
                    }}>
                    Очистить
                    </button>
                    <button className={styles.filterButton} onClick={() => {
                        applyFilters(newSelectedFilters)
                    }}>
                        Применить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DesktopFilterCatalog;
