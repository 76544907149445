import React, { useContext, useEffect, useRef, useState } from 'react'
import './AddTraining.css'
import CabinetSellerService from '../../../../../services/CabinetSellerService'
import {
	ICity,
	IDropdownOption,
	IRegion,
	ITraining
} from '../../../../../models/models'
import HomeService from '../../../../../services/HomeService'
import CustomDropdown from '../../../../customUI/CustomDropdown/CustomDropdown'
import KladrInputCity from '../../../../customUI/CustomDropdown/KladrInputCity'
import KladrInputRegion from '../../../../customUI/CustomDropdown/KladrInputRegion'
import CustomInput from '../../../../customUI/CustomInput/CustomInput'
import { useNavigate, useParams } from 'react-router-dom'
import { SpaRoutes } from '../../../../../Routes/spaRoutes'
import { Context } from '../../../../../index'
import CustomNavigateHeader from '../../../../customUI/CustomNavigateHeader/CustomNavigateHeader'
import * as dayjs from 'dayjs'

export default function AddUpdateTraining() {
	const navigate = useNavigate()
	const { store } = useContext(Context)
	
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const [drag, setDrag] = useState(false)
	const [eventFile, setEventFile] = useState<File>()
	const [eventPreview, setEventPreview] = useState<string>()
	const inputRef = useRef(null)
	const [regionName, setRegionName] = useState('')
	const [cityName, setCityName] = useState('')
	// const [regions, setRegions] = useState<IRegion[]>([])
	// const [cities, setCities] = useState<ICity[]>([])
	const [trainingData, setTrainingData] = useState<ITraining>({})
	const [formatName, setFormatName] = useState('Формат')
	// const [categoryName, setCategoryName] = useState('Категория')
	const [isOnline, setIsOnline] = useState(false)
	
	const [loadedTrainingData, setLoadedTrainingData] = useState(false)
	const [loadedRegions, setLoadedRegions] = useState(false)
	
	// const [isVisible, setIsVisible] = useState(true)
	const [regionIdError, setRegionIdError] = useState(false)
	const [regionIdErrorMsg, setRegionIdErrorMsg] = useState<any>([])
	const [regionError, setRegionError] = useState<string>('')
	const [selectedRegionId, setSelectedRegionId] = useState<string>('')
	const [regionSuccess, setRegionSuccess] = useState<string>('true')
	const [citySuccess, setCitySuccess] = useState<string>('true')
	const [cityError, setCityError] = useState<string>('')
	const [cityIdError, setCityIdError] = useState(false)
	const [cityIdErrorMsg, setCityIdErrorMsg] = useState<any>([])
	
	const [oldStartDate, setOldStartDate] = useState('')
	
	const dayjs = require('dayjs')
	
	let { eventId } = useParams()
	if (eventId === undefined) {
		eventId = '0'
	}
	
	useEffect(() => {
		if (!loadedTrainingData) {
			if (eventId != '0') {
				store.DataLoadingON()
				CabinetSellerService.getCalendarEvent({ Info1: eventId }).then((response) => {
					setTrainingData({
						...response.data,
						startDate: response.data.startDate.split('T')[0],
						startTime: response.data.startDate.split('T')[1],
						endDate: response.data.endDate.split('T')[0],
						endTime: response.data.endDate.split('T')[1]
					})
					setOldStartDate(response.data.startDate.split('T')[0])
					setRegionName(response.data.regionName)
					setCityName(response.data.cityName)
					setFormatName(response.data.format)
					if (response.data.format === 'Онлайн') {
						setIsOnline(true)
					}
					setEventPreview(
						response.data['imageUrl']
							? store.imgServer + response.data['imageUrl']
							: ''
					)
				}).catch(() => store.ErrorON()).finally(() => {
					store.DataLoadingOFF()
					setLoadedTrainingData(true)
				})
			} else {
				setFormatName('Очно')
				setIsOnline(false)
				setTrainingData({
					startDate: dayjs().format('YYYY-MM-DD'),
					startTime: dayjs().format('HH:mm:00')
				})
			}
		}
	}, [])
	
	
	const createFormData = (data: any) => {
		return Object.keys(data).reduce((formData, key) => {
			formData.append(key, data[key])
			return formData
		}, new FormData())
	}
	const refactorUserData = (data: { [x: string]: any }) => {
		for (const key in data) {
			if (data[key] === null || data[key] === undefined) delete data[key]
		}
		return data
	}
	
	const handleTrainingClick = async (e: any) => {
		e.preventDefault()
		if(!regionName || regionSuccess === 'false') {
			setRegionSuccess('false')
			return
		}
		if(!cityName || citySuccess === 'false') {
			setCitySuccess('false')
			return
		}
		store.DataLoadingON()

		
		if (eventId === '0') {
			let mydata = {
				title: trainingData['title'],
				regionName: formatName === 'Онлайн' ? '' : trainingData['regionName'],
				cityName: formatName === 'Онлайн' ? '' : trainingData['cityName'],
				format: formatName,
				description: trainingData['description'],
				startDate: `${trainingData['startDate']}T${trainingData['startTime']}`,
				endDate: `${trainingData['endDate']}T${trainingData['endTime']}`,
				image: eventFile
			}
			store.DataLoadingON()
			await CabinetSellerService.postCreateNewCalendarEvent(mydata).then(() => {
				alert('Обучение добавлено')
				navigate(SpaRoutes.TRAINING)
			}).catch((error) => {
				alert('Что-то пошло не так')
			}).finally(() => {
				store.DataLoadingOFF()
			})
		} else {
			let mydata = {
				id: eventId,
				title: trainingData['title'],
				regionName: formatName === 'Онлайн' ? '' : trainingData['regionName'],
				cityName: formatName === 'Онлайн' ? '' : trainingData['cityName'],
				format: formatName,
				description: trainingData['description'],
				startDate: `${trainingData['startDate']}T${trainingData['startTime']}`,
				endDate: `${trainingData['endDate']}T${trainingData['endTime']}`,
				image: eventFile,
				organisator: trainingData['organisator'],
				organisatorId: trainingData['organisatorId']
			}
			store.DataLoadingON()
			const data = createFormData(refactorUserData(mydata))
			await CabinetSellerService.postUpdateNewCalendarEvent(data).then(() => {
				alert('Обучение изменено')
			}).catch((error) => {
				alert('Что-то пошло не так')
			}).finally(() => {
				store.DataLoadingOFF()
			})
		}
	}
	
	useEffect(() => {
		setLoadedRegions(true)
	}, [loadedTrainingData])
	
	/*useEffect(() => {
		if (loadedTrainingData && loadedRegions) {
			setRegionName(trainingData['regionName'] ? trainingData['regionName'] : '')
			if (trainingData['regionName']) {
				HomeService.getKladrRegion(trainingData['regionName'].split(' ')[0]).then(response => {
					if (response.data.output.result.result) {
						let kladrResult = response.data.output.result.result
						kladrResult.map((item: { id: string; name: string; type: string; guid: string; parentGuid: string; }) => {
							if (item.id !== 'Free' && item.name + ' ' + item.type.toLowerCase() === trainingData['regionName']) {
								setSelectedRegionId(item.id)
								setRegionName(item.name)
							}
						})
					}
				}).then(() => {
					setCityName(trainingData['cityName'] ? trainingData['cityName'] : '')
				})
			}

		}
	}, [loadedTrainingData])*/

	
	let formatsTraining: IDropdownOption[] = [
		{ key: '1', name: 'Очно' },
		{ key: '2', name: 'Онлайн' }
	]
	
	let categoriesTraining: IDropdownOption[] = [
		{ key: '1', name: 'Косметика' },
		{ key: '2', name: 'Инъекции' }
	]
	
	const onRegionsSelectChange = (name: string, key: string) => {
		setRegionSuccess('true')
		const RegionName = name
		setTrainingData({
			...trainingData,
			["regionName"]: RegionName
		})
		setSelectedRegionId(key)
		setCityName('')
		setCitySuccess('false')
	}
	
	const onCitySelectChange = (val: string) => {
		setCitySuccess('true')
		let CityName = val
		setTrainingData({
			...trainingData,
			['cityName']: CityName
		})
	}
	
	
	useEffect(() => {
		if (!eventFile) {
			setEventFile(undefined)
			return
		}
		
		const objectUrl = URL.createObjectURL(eventFile)
		setEventPreview(objectUrl)
		
		// free memory when ever this component is unmounted
		return () => URL.revokeObjectURL(objectUrl)
	}, [eventFile])
	
	const selectEventPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files || event.target.files.length === 0) {
			setEventPreview(undefined)
			return
		}
		// I've kept this example simple by using the first image instead of multiple
		setEventFile(event.target.files[event.target.files.length - 1])
	}
	
	const onBtnPictureClick = () => {
		// @ts-ignore
		inputRef.current?.click()
	}
	
	const dragOver = (e: any) => {
		e.preventDefault()
	}
	
	const dragEnter = (e: any) => {
		e.preventDefault()
		setDrag(true)
	}
	
	const dragLeave = (e: any) => {
		e.preventDefault()
		setDrag(false)
	}
	
	const fileDrop = (e: any) => {
		e.preventDefault()
		setEventFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
		setDrag(false)
	}
	const updateUI = (val: string) => {
		if (val === '2') {
			setIsOnline(true)
		} else {
			setIsOnline(false)
		}
	}
	
	return (
		<div className='addTraining-wrapper'>
			<form
				className='addTraining-container'
				onSubmit={(e) => handleTrainingClick(e)}
			>
				<CustomNavigateHeader
					title={eventId === '0' ? 'Добавить обучение' : 'Изменить обучение'}
					withTrash={false}
				/>
				
				<div className='addTraining-inputs'>
					{!isOnline && (
						<>
							<div className='addTraining-dropdown'>
								<p>Регион</p>
								<KladrInputRegion

									// icon={isVisible}
									placeholder={'Регион'}
									// disabled={!isVisible}
									error={regionIdError}
									errorMsg={regionIdErrorMsg}
									dropdownSize={'large'}
									value={regionName}
									valueSetter={setRegionName}
									successSetter={setRegionSuccess}
									onChangeHandler={(name, key) => {
										onRegionsSelectChange(name, key)
									}}
								/>
								{regionSuccess === 'false' &&
									<div className={'invalid-feedback'} style={{ color: 'red' }}>Обязательно выберите регион из
										списка!</div>}
							</div>
							
							<div className='addTraining-dropdown'>
								<p>Город</p>
								<KladrInputCity
									styleDropdownDisplay={'supplier-dropDown-input'}
									placeholder={'Город'}
									// icon={isVisible}
									regionId={selectedRegionId}
									// disabled={!isVisible}
									error={cityIdError}
									value={cityName}
									dropdownSize={'large'}
									errorMsg={cityIdErrorMsg}
									successSetter={setCitySuccess}
									valueSetter={setCityName}
									onChangeHandler={onCitySelectChange}
								/>
								{citySuccess === 'false' &&
									<div className={'invalid-feedback'} style={{ color: 'red' }}>Обязательно выберите город из
										списка!</div>}
							</div>
						</>
					)}
					
					<div className='addTraining-dropdown'>
						<p>Формат</p>
						<CustomDropdown
							styleDropdownDisplay={' training-dropDown'}
							styleDropdownList1={' training-dropDown-list'}
							styleDropdownList2={' training-dropDown-list2'}
							dropdownSize={'large'}
							icon={true}
							options={formatsTraining}
							value={formatName}
							valueSetter={setFormatName}
							onChangeHandler={updateUI}
						/>
					</div>
					
					<div className='addTraining-input'>
						<p>Название</p>
						<CustomInput
							required={true}
							name={'title'}
							icon={false}
							value={trainingData['title']}
							placeHolder={'Название'}
							onChange={(event) => {
								setTrainingData({ ...trainingData, title: event.target.value })
							}}
							inputSize={'large'}
						/>
					</div>
				</div>
				
				{/* <div className='addTraining-dropdown'> */}
				{/* 	<p>Категория</p> */}
				{/* 	<CustomDropdown */}
				{/* 		styleDropdownDisplay={' training-dropDown'} */}
				{/* 		styleDropdownList1={' training-dropDown-list'} */}
				{/* 		styleDropdownList2={' training-dropDown-list2'} */}
				{/* 		dropdownSize={'large'} */}
				{/* 		icon={true} */}
				{/* 		options={categoriesTraining} */}
				{/* 		value={categoryName} */}
				{/* 		valueSetter={setCategoryName} */}
				{/* 		onChangeHandler={updateUI} */}
				{/* 	/> */}
				{/* </div> */}
				
				<div className='addTraining-description'>
					<p>Описание</p>
					<textarea
						value={trainingData['description']}
						onChange={(event) =>
							setTrainingData({
								...trainingData,
								description: event.target.value
							})
						}
					/>
				</div>
				
				<div className='addTraining-choiceTime'>
					<div className='addTraining-choiceTime-item'>
						<p>Дата начала</p>
						<input
							type={'date'}
							required
							min={
								eventId === '0' ? new Date().toLocaleDateString('fr-ca') : ''
							}
							value={trainingData['startDate']}
							onChange={(event) =>
								setTrainingData({
									...trainingData,
									startDate: event.target.value,
									endDate: ''
								})
							}
						/>
					</div>
					
					<div className='addTraining-choiceTime-item'>
						<p>Время начала</p>
						<input
							type={'time'}
							required
							value={trainingData['startTime']}
							onChange={(event) =>
								setTrainingData({
									...trainingData,
									startTime: `${event.target.value}:00`
								})
							}
						/>
					</div>
					
					<div className='addTraining-choiceTime-item'>
						<p>Дата окончания</p>
						<input
							type={'date'}
							required
							min={
								trainingData['startDate']
									? trainingData['startDate']
									: new Date().toLocaleDateString('fr-ca')
							}
							value={trainingData['endDate'] ? trainingData['endDate'] : ''}
							disabled={false}
							onChange={(event) =>
								setTrainingData({
									...trainingData,
									endDate: event.target.value
								})
							}
						/>
					</div>
					
					<div className='addTraining-choiceTime-item'>
						<p>Время окончания</p>
						<input
							type={'time'}
							required
							value={trainingData['endTime'] ? trainingData['endTime'] : ''}
							onChange={(event) =>
								setTrainingData({
									...trainingData,
									endTime: `${event.target.value}:00`
								})
							}
						/>
					</div>
				</div>
				
				<div className='addTraining-uploadPhoto'>
					<p>Обложка мероприятия</p>
					
					<div
						className={
							'addTraining-photo-image' +
							(drag ? ' addTraining-photo-image-dragEnter' : '')
						}
						onDragOver={dragOver}
						onDragEnter={dragEnter}
						onDragLeave={dragLeave}
						onDrop={fileDrop}
					>
						{eventPreview ? (
							<img src={eventPreview} alt={'product picture'} />
						) : (
							<div className={'addTraining-photo-image-text'}>
								Поместите сюда фото
							</div>
						)}
					</div>
					
					<div className='addTraining-uploadPhoto-overview'>
						<input
							ref={inputRef}
							style={{ display: 'none' }}
							type='file'
							name={'profilePicture'}
							onChange={selectEventPicture}
							accept=".jpg, .png, .jpeg"
						/>
						
						<div
							className={'addTraining-uploadPhoto-button'}
							onClick={onBtnPictureClick}
						/>
					</div>
				</div>
				
				<div>
					<div className='addTraining-footer-btn'>
						<input
							type={'submit'}
							value={
								eventId === '0' ? 'Добавить обучение' : 'Изменить обучение'
							}
						/>
					</div>
				</div>
			</form>
		</div>
	)
}
