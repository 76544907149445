import React, {useEffect, useState} from "react";
import SuppliersCompanyItem from "./SuppliersCompanyItem";
import styles from "../SuppliersPage.module.scss";

interface Props {
    dataOverviewDealer: any[]
}

const SuppliersCompanyList = ({dataOverviewDealer}:Props)=> {
    const ListOfSuppliers = dataOverviewDealer.map((company:any) => {
        return <SuppliersCompanyItem key={company.id} company={company} numberOfIndex={dataOverviewDealer.indexOf(company)+1} />
    })

    return(
        dataOverviewDealer && (
            <div className={styles.supCompanyList}>
                {ListOfSuppliers}
            </div>
        )
    )
}
export default SuppliersCompanyList;