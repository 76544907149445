import React, {useContext, useEffect, useRef, useState} from 'react';
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import "./SupplierReklama.css"
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {IReklama} from "../../../../models/models";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import ReklamaItem from "./ReklamaItem";
import {Context} from "../../../../index";
import PreviewReklama from "../../../customUI/PreviewReklama/PreviewReklama";
import {useParams} from "react-router-dom";

interface ISupplierReklama{
    isCreate?: boolean
}

const SupplierReklama = ({isCreate=false}:ISupplierReklama) => {


    const [typeReklama, settypeReklama] = useState(0)

    const [allReklama, setAllReklama] = useState<IReklama[]>([])

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);

    const [newReklamaData, setNewReklamaData] = useState<IReklama>({})

    const [reklamaMainFile, setReklamaMainFile] = useState<File>()
    const [reklamaMainPreview, setReklamaMainPreview] = useState<string | undefined>()
    const [dragMain, setDragMain] = useState(false)

    const [reklamaMiniFile, setReklamaMiniFile] = useState<File>()
    const [reklamaMiniPreview, setReklamaMiniPreview] = useState<string | undefined>()
    const [dragMini, setDragMini] = useState(false)

    const [reklamaItems, setReklamaItems] = useState<JSX.Element[]>([])


    // const [openNewReklama, setOpenNewReklama] = useState(false)
    let openNewReklama = isCreate
    const [openAll, setOpenAll] = useState(false)

    const [previewReklama, setPreviewReklama] = useState<JSX.Element | null>(null)

    const {store} = useContext(Context)

    const getData = () => {
        store.DataLoadingON()
        CabinetSellerService.getMyEntertainment()
            .then((r) => setAllReklama(r.data))
            .catch((e) => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        getData()
        setOpenAll(false)
    }, [])

    useEffect(() => {
        setReklamaItems(allReklama.filter(r => r.type === typeReklama)
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
            .map((r, index) => {
                return <ReklamaItem reklama={r} openProps={openAll} updateData={getData} key={r.reklamaId}/>
            }))
    }, [allReklama, typeReklama, openAll])

    const onBtn1PictureClick = () => {
        // @ts-ignore
        input1Ref.current?.click();
    }

    const onBtn2PictureClick = () => {
        // @ts-ignore
        input2Ref.current?.click();
    }
    const selectMainPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setReklamaMainPreview(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setReklamaMainFile(event.target.files[event.target.files.length - 1])
    };

    const selectMiniPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setReklamaMiniPreview(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setReklamaMiniFile(event.target.files[event.target.files.length - 1])
    };

    useEffect(() => {
        if (!reklamaMainFile) {
            //setElementPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(reklamaMainFile)
        setReklamaMainPreview(objectUrl)

        let img = new Image()
        img.src = objectUrl
        img.onload = () => {
            if (img.width !== (typeReklama === 0 ? 1600 : 1800) || img.height !== (typeReklama === 0 ? 500 : 150))
                alert(`Острожно! Изображение не соответсвует размерам ${typeReklama === 0 ? "1600x500" : "1800x150"}`)
        }
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [reklamaMainFile])

    useEffect(() => {
        if (!reklamaMiniFile) {
            //setElementPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(reklamaMiniFile)
        setReklamaMiniPreview(objectUrl)

        let img = new Image()
        img.src = objectUrl
        img.onload = () => {
            if (img.width !== (typeReklama === 0 ? 390 : 600) || img.height !== (typeReklama === 0 ? 200 : 70))
                alert(`Острожно! Изображение не соответсвует размерам ${typeReklama === 0 ? "390x200" : "600x70"}`)
        }
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [reklamaMiniFile])

    const createFormData = (data: any) => {
        return Object.keys(data).reduce((formData, key) => {
            formData.append(key, data[key]);
            return formData;
        }, new FormData());
    };
    const refactorData = (data: { [x: string]: any; }) => {
        for (const key in data) {
            if (data[key] === null || data[key] === undefined)
                delete data[key]
        }
        return data
    }

    const AddReklama = (status: number | undefined) => {
        if (status === 1) {
            if (!window.confirm("Рекаламу на модерации нельзя будет редактировать\nПродолжить?")) {
                return
            }
        }
        let newReklama = {
            reklamaId: "new_reklama",
            type: typeReklama,
            name: newReklamaData.name,
            imageBig: reklamaMainFile,
            imageSmall: reklamaMiniFile,
            description: newReklamaData.description,
            link: newReklamaData.link,
            linkName: newReklamaData.linkName,
            status: status
        }

        let data = createFormData(refactorData(newReklama))
        store.DataLoadingON()
        CabinetSellerService.addUpdateEntertainment(data)
            .then(() => {
                if (status === 1)
                    alert("Баннер успешно создан и отправлен на модерацию")
                else
                    alert("Баннер успешно создан")
                getData()
                setNewReklamaData({
                    type: typeReklama,
                    name: "",
                    imageBig: undefined,
                    imageSmall: undefined,
                    description: "",
                    link: "",
                    linkName: "",
                    status: 0
                })
                setReklamaMainPreview(undefined)
                setReklamaMiniPreview(undefined)
                setReklamaMainFile(undefined)
                setReklamaMiniFile(undefined)
                //setOpenNewReklama(false)
                openNewReklama = false
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }


    return (
        <div className={"supplierReklama"}>
            <CustomNavigateHeader title={"Ваша реклама"} withTrash={false}/>
            <div className={"supplierReklama-types"}>
                <button data-active={typeReklama === 0} onClick={() => {
                    settypeReklama(0)
                    setNewReklamaData({})
                    setReklamaMainPreview(undefined)
                    setReklamaMiniPreview(undefined)
                    setReklamaMainFile(undefined)
                    setReklamaMiniFile(undefined)
                }}>Верхний баннер</button>
                <button data-active={typeReklama === 1} onClick={() => {
                    settypeReklama(1)
                    setReklamaMainPreview(undefined)
                    setReklamaMiniPreview(undefined)
                    setReklamaMainFile(undefined)
                    setReklamaMiniFile(undefined)
                }}>Нижний баннер</button>
            </div>
            <div className={"supplierReklama-openAll"}>
                <button data-active={openAll}
                        onClick={() => setOpenAll(!openAll)}>{openAll ? "Скрыть все баннеры" : "Показать все баннеры"}</button>
            </div>
            {previewReklama && previewReklama}
            <details className={"supplierReklama-reklama-container"} open={openNewReklama}>
                <summary className={"supplierReklama-reklama-header"}
                         onClick={() => openNewReklama = !openNewReklama}>
                    <div>Создать новый баннер</div>
                </summary>
                <div className={"supplierReklama-reklama"}>
                    <div className={"supplierReklama-reklama-images"}>
                        <div className={"supplierReklama-reklama-preview"}>
                            <div className={"supplierReklama-reklama-hint"}>
                                Слайд для компьютера
                            </div>
                            <div
                                className={"supplierReklama-reklama-photo-image-desktop" + (dragMain ? " supplierReklama-reklama-photo-image-dragEnter" : "")}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={(e) => {
                                    e.preventDefault();
                                    setDragMain(true)
                                }}
                                onDragLeave={(e) => {
                                    e.preventDefault();
                                    setDragMain(false)
                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    setReklamaMainFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
                                    setDragMain(false)
                                }}
                                style={{aspectRatio: typeReklama === 0 ? "1600/500" : "1800/150"}}
                            >
                                {reklamaMainPreview ?
                                    <img src={reklamaMainPreview}
                                         style={{aspectRatio: typeReklama === 0 ? "1600/500" : "1800/150"}}/>
                                    :
                                    <div className={"supplierReklama-reklama-image-text"}>
                                        Поместите сюда фото {typeReklama === 0 ? "1600x500" : "1800x150"}
                                    </div>
                                }
                            </div>
                            <div className={"supplierReklama-reklama-preview-buttons"}>
                                <button onClick={() => {
                                    if (reklamaMainPreview) {
                                        setPreviewReklama
                                        (<PreviewReklama key={0} reklamaImg={reklamaMainPreview}
                                                         typePreview={typeReklama}
                                                         typeDevice={"desktop"}
                                                         description={newReklamaData.description}
                                                         linkName={newReklamaData.linkName}
                                                         link={newReklamaData.link}
                                                         onClose={() => setPreviewReklama(null)}
                                        />)
                                    } else alert("Нет картинки")
                                }}>
                                    Предпросмотр
                                </button>
                                <input
                                    ref={input1Ref}
                                    style={{display: 'none'}}
                                    type="file"
                                    name={"profilePicture"}
                                    onChange={selectMainPicture}
                                    accept=".jpg, .png, .jpeg"
                                />
                                <button onClick={onBtn1PictureClick}></button>
                            </div>
                        </div>
                        <div className={"supplierReklama-reklama-preview"}>
                            <div className={"supplierReklama-reklama-hint"}>
                                Слайд для телефона
                            </div>
                            <div
                                data-type={typeReklama}
                                className={"supplierReklama-reklama-photo-image-mobile" + (dragMini ? " supplierReklama-reklama-photo-image-dragEnter" : "")}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={(e) => {
                                    e.preventDefault();
                                    setDragMini(true)
                                }}
                                onDragLeave={(e) => {
                                    e.preventDefault();
                                    setDragMini(false)
                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    setReklamaMiniFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
                                    setDragMini(false)
                                }}
                                style={{aspectRatio: typeReklama === 0 ? "390/200" : "600/50"}}
                            >
                                {reklamaMiniPreview ?
                                    <img src={reklamaMiniPreview}
                                         style={{aspectRatio: typeReklama === 0 ? "390/200" : "600/50"}}/>
                                    :
                                    <div className={"supplierReklama-reklama-image-text"}>
                                        Поместите сюда фото {typeReklama === 0 ? "390x200" : "600x70"}
                                    </div>
                                }
                            </div>
                            <div className={"supplierReklama-reklama-preview-buttons"}>
                                <button onClick={() => {
                                    if (reklamaMiniPreview) {
                                        setPreviewReklama
                                        (<PreviewReklama key={0} reklamaImg={reklamaMiniPreview}
                                                         typePreview={typeReklama}
                                                         typeDevice={"mobile"}
                                                         description={newReklamaData.description}
                                                         linkName={newReklamaData.linkName}
                                                         link={newReklamaData.link}
                                                         onClose={() => setPreviewReklama(null)}
                                        />)
                                    } else alert("Нет картинки")
                                }}>
                                    Предпросмотр
                                </button>
                                <input
                                    ref={input2Ref}
                                    style={{display: 'none'}}
                                    type="file"
                                    name={"profilePicture"}
                                    onChange={selectMiniPicture}
                                    accept=".jpg, .png, .jpeg"
                                />
                                <button onClick={onBtn2PictureClick}></button>
                            </div>
                        </div>
                    </div>
                    <div className={"supplierReklama-reklama-settings"}>
                        <div className={"supplierReklama-reklama-setting"}>
                            <div className={"supplierReklama-reklama-hint"}>
                                Название рекламы
                                <div>Название видно только вам</div>
                            </div>
                            <CustomInput name={"name"} inputSize={"small"}
                                         value={newReklamaData.name}
                                         onChange={(e) => setNewReklamaData({...newReklamaData, name: e.target.value})}
                                         type={"text"}
                                         placeHolder={"Название рекламы"}

                            />
                        </div>
                        <div className={"supplierReklama-reklama-setting"}>
                            <div className={"supplierReklama-reklama-hint"}>
                                Текст рекламы
                                <div>Текст рекламы виден пользователям</div>
                            </div>
                            <textarea value={newReklamaData.description}
                                      placeholder={"Текст рекламы"}
                                      onChange={(e) => {
                                          setNewReklamaData({...newReklamaData, description: e.target.value})
                                      }}/>
                        </div>
                        <div className={"supplierReklama-reklama-setting"}>
                            <div className={"supplierReklama-reklama-hint"}>
                                Название кнопки
                                <div>При помощи кнопки пользователь сможет перейти по ссылке</div>
                            </div>
                            <CustomInput name={"urlName"} inputSize={"small"}
                                         value={newReklamaData.linkName}
                                         onChange={(e) => setNewReklamaData({
                                             ...newReklamaData,
                                             linkName: e.target.value
                                         })}
                                         type={"text"}
                                         placeHolder={"Название кнопки"}
                            />
                        </div>
                        <div className={"supplierReklama-reklama-setting"}>
                            <div className={"supplierReklama-reklama-hint"}>
                                Ссылка
                            </div>
                            <CustomInput name={"URL"} inputSize={"small"}
                                         value={newReklamaData.link}
                                         onChange={(e) => setNewReklamaData({...newReklamaData, link: e.target.value})}
                                         type={"text"}
                                         placeHolder={"Ссылка"}
                            />
                        </div>
                        <div className={"supplierReklama-reklama-hint-status"} data-status={undefined}>

                        </div>
                        <div className={"supplierReklama-reklama-settings-buttons"}>
                            <button onClick={() => AddReklama(undefined)}>Добавить</button>
                            <button onClick={() => AddReklama(1)}>Отправить на модерацию</button>
                        </div>
                    </div>
                </div>
            </details>
            {reklamaItems && reklamaItems}
        </div>
    );
};

export default SupplierReklama;