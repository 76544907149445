import {IBrandsFilterData, ISellersFilterData} from "../models/Catalog/Catalog";

export const searchSuppliersByName = (suppliers:  ISellersFilterData[] | null | undefined, searchValue: string) => {
	const filterSuppliers = suppliers?.filter(({ displayedName }) =>
		displayedName.toLowerCase().includes(searchValue.toLowerCase()),
	)
	if (searchValue !== '') {
		return filterSuppliers
	} else {
		return suppliers
	}
}

export const searchBrandsByName = (brands: IBrandsFilterData[] | null | undefined, searchValue: string) => {
	const filterBrands = brands?.filter(({ brandName }) =>
		brandName.toLowerCase().includes(searchValue.toLowerCase()),
	)
	if (searchValue !== '') {
		return filterBrands
	} else {
		return brands
	}
}
